export default class Contact {
	constructor() {
		this.form = document.querySelector('.contact-form')
		this.formElements = {
			firstname: this.form.querySelector('#firstname'),
			lastname: this.form.querySelector('#lastname'),
			email: this.form.querySelector('#email'),
			message: this.form.querySelector('#message'),
			token: this.form.querySelector('#form-token')
		}
		this.submitButton = this.form.querySelector('.contact-formButton')
		this.warningMessage = this.form.querySelector('.contact-warning')
		this.loader = this.form.querySelector('.contact-loader')
		this.warningsArray = []
		this.api = this.form.getAttribute('data-api')
		this.website = this.form.getAttribute('data-website')
		this.googleRecaptchaClientKey = this.form.getAttribute('data-publickey')
		this.head = document.querySelector('head')

		this.onSubmit = this.onSubmit.bind(this)
		this.initCaptcha = this.initCaptcha.bind(this)
	}

	init() {
		this.insertRecaptchaScriptApi()
		this.removeAttributes()
		this.addEvents()
	}

	removeAttributes() {
		this.form.removeAttribute('data-api')
		this.form.removeAttribute('data-website')
		this.form.removeAttribute('data-url')
		this.form.removeAttribute('data-publickey')
	}

	addEvents() {
		this.submitButton.addEventListener('click', this.onSubmit)
		Object.values(this.formElements).forEach((element) => {
			if (element.getAttribute('id') !== 'form-token') {
				element.addEventListener('blur', this.handleBur)
			}
		})
		this.formElements.message.addEventListener('input', () =>
			this.autoGrow(this.formElements.message)
		)
		window.addEventListener('load', this.initCaptcha)
	}

	handleBur(e) {
		const target = e.target
		target.classList[target.value.length ? 'add' : 'remove']('filled')
	}

	/**
	 * Auto grow text area HTML element
	 * @param {HTMLElement} element - text area
	 */
	autoGrow(element) {
		element.style.height = '36px'
		element.style.height = `${element.scrollHeight}px`
	}

	/**
	 * Insert Google captcha script API
	 */
	insertRecaptchaScriptApi() {
		window.initCaptcha = () => {
			this.initCaptcha()
		}
		const script = document.createElement('script')
		script.setAttribute(this.consent?.recaptcha ? 'type' : 'data-type', 'application/javascript')
		script.setAttribute(this.consent?.recaptcha ? 'name' : 'data-name', 'recaptcha')
		script.setAttribute(
			'src',
			`https://www.google.com/recaptcha/api.js?render=${this.googleRecaptchaClientKey}`
		)
		script.setAttribute('onload', 'initCaptcha()')
		this.head.appendChild(script)
	}

	initCaptcha() {
		grecaptcha.ready(() => {
			this.grecaptcha = grecaptcha
		})
	}

	async onSubmit(e) {
		e.preventDefault()
		this.token = await this.grecaptcha.execute(this.googleRecaptchaClientKey, {
			action: 'submit'
		})

		this.getFormValues()
	}

	getFormValues() {
		this.removeErrors()
		this.warningMessage.textContent = ''
		const errors = this.validateForm()

		if (errors.length > 0) {
			this.displayFormErrors(errors)
		} else {
			const data = {}
			data.captcha = this.token

			Object.values(this.formElements).forEach((element) => {
				data[element.getAttribute('id')] = element.value
			})

			this.sendData(JSON.stringify({ data }))
		}
	}

	validateForm() {
		this.warningsArray = []

		Object.values(this.formElements).forEach((element) => {
			const elementName = element.name
			const type = element.type
			const value = element.value

			// don't check the following elements:
			if (!elementName || ['reset', 'submit', 'button'].includes(type)) return false

			if (value === '') {
				this.warningsArray.push({
					error: elementName,
					message: 'Ce champ est requis !'
				})
			} else if (
				(elementName === 'firstname' || elementName === 'lastname') &&
				(!value.match(/^[^<>"'@%$/\\{}[\]&*£$¤§°%#|()]*$/) ||
					value.match(/^[0-9]*$/) ||
					value.length <= 1 ||
					value.length > 40)
			) {
				this.warningsArray.push({
					error: elementName,
					message: `${elementName === 'firstname' ? 'Le prénom' : 'Le nom'} est invalide !`
				})
			} else if (
				elementName === 'email' &&
				(!value.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				) ||
					value.length <= 6 ||
					value.length > 40)
			) {
				this.warningsArray.push({
					error: elementName,
					message: "L'email est invalide !"
				})
			} else if (elementName === 'message') {
				if (value.length < 10) {
					this.warningsArray.push({
						error: elementName,
						message: 'Votre message est trop court !'
					})
				} else if (value.length > 1000) {
					this.warningsArray.push({
						error: elementName,
						message: 'Message trop long ! (1000 caractères maximum)'
					})
				} else if (
					value
						.toLowerCase()
						.match(
							/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
						)
				) {
					this.warningsArray.push({
						error: elementName,
						message: 'Aucun lien autorisé !'
					})
				}
			}
		})

		return this.warningsArray
	}

	displayFormErrors(errorArray) {
		this.warningMessage.classList.remove('success')

		errorArray.forEach((element) => {
			if (this.formElements[element.error]) {
				this.formElements[element.error].classList.add('error')
				this.formElements[element.error].parentNode.querySelector(
					'.contact-fieldError'
				).textContent = element.message
			} else {
				this.form
					.querySelector(`.${element.error}`)
					.parentNode.querySelector('.contact-fieldError').textContent = element.message
			}
		})
	}

	removeErrors() {
		Object.values(this.formElements).forEach((element) => {
			element.classList.remove('error')
			element.parentNode.querySelector('.contact-fieldError').textContent = ''
		})
	}

	resetForm() {
		this.removeErrors()
		this.form.reset()
		this.warningMessage.classList.remove('success')
		this.warningMessage.classList.remove('error')
		this.warningMessage.textContent = ''
		this.submitButton.classList.remove('success')

		Object.values(this.formElements).forEach((element) => {
			element.classList.remove('filled')
		})
	}

	async sendData(data) {
		this.submitButton.classList.add('loading')
		try {
			const rawResponse = await fetch(this.api, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: data
			})
			const content = await rawResponse.json()
			const response = content

			setTimeout(() => {
				if (response.warning && response.warning.status === 'success') {
					this.submitButton.classList.remove('loading')
					this.resetForm()
					this.warningMessage.innerHTML = response.warning.message
					this.warningMessage.classList.add('success')
					this.submitButton.classList.add('success')
				} else {
					this.submitButton.classList.remove('loading')
					this.warningMessage.classList.remove('success')

					Object.keys(response).forEach((element) => {
						if (response.warning) {
							this.warningMessage.classList.add('error')
							this.warningMessage.textContent = 'Une erreur est survenue, veuillez réessayer.'
						} else {
							this.formElements[element].parentNode.querySelector(
								'.contact-fieldError'
							).textContent = response[element].message
							this.formElements[element].classList.add('error')
						}
					})
				}
			}, 2500)
		} catch (err) {
			this.submitButton.classList.remove('loading')
			this.warningMessage.classList.remove('success')
			this.warningMessage.classList.add('error')
			this.warningMessage.textContent = 'Une erreur est survenue, veuillez réessayer.'

			// Request not gone (pas de réseau /problème CORS)
			if (!err.request) {
				console.warn('Error: Request is not gone')
			} else if (!err.response) {
				// No response from server / timeout
				console.warn('Error: Request timeout')
			} else if (err.response.status === 400) {
				// 400 http error from server
				console.warn(err.response.data.message)
			}
		}
	}
}
