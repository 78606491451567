export default class References {
	constructor() {
		this.container = document.querySelector('.references')
		this.list = this.container.querySelector('.references-list')
		this.buttons = this.container.querySelector('.references-buttons')
		this.itemWidth = document.querySelector('.references-listItem').offsetWidth
		this.leftButton = this.buttons.querySelector('.left')
		this.rightButton = this.buttons.querySelector('.right')

		this.scroll = this.scroll.bind(this)
		this.updateButtonState = this.updateButtonState.bind(this)
	}

	init() {
		this.addEvents()
		this.updateButtonState()
	}

	addEvents() {
		this.buttons.addEventListener('click', this.scroll)
		this.list.addEventListener('scroll', this.updateButtonState)
	}

	scroll(e) {
		const direction = e.target.classList.contains('left')
			? -1
			: e.target.classList.contains('right')
				? 1
				: 0
		this.list.scrollBy({
			left: direction * this.itemWidth,
			top: 0,
			behavior: 'smooth'
		})
	}

	updateButtonState() {
		const scrollLeft = this.list.scrollLeft
		const maxScrollLeft = this.list.scrollWidth - this.list.clientWidth

		this.leftButton.classList[scrollLeft <= 0 ? 'add' : 'remove']('disabled')
		this.rightButton.classList[scrollLeft >= maxScrollLeft ? 'add' : 'remove']('disabled')
	}
}
